import { RouteNames } from '@/types/routes';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteNames.PLAYGROUND,
    children: [
      {
        path: 'playground',
        name: RouteNames.COMPLETE,
        components: {
          default: () => import('@/modules/Playground/views/Playground.vue'),
        },
      },
      {
        path: 'chat',
        name: RouteNames.CHAT,
        meta: {
          requiresBeta: true,
        },
        components: {
          default: () => import('@/modules/Playground/views/Chat.vue'),
        },
      },
    ],
  },
];
export default routes;
