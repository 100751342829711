import evaluateRoutes from '@/modules/Evaluate/router';
import finetuneRoutes from '@/modules/Finetune/router';
import knowledgeRoutes from '@/modules/Knowledge/router';
import monitorRoutes from '@/modules/Monitor/router';
import playgroundRoutes from '@/modules/Playground/router';
import traceRoutes from '@/modules/Traces/router';
import {
  createRouter,
  createWebHistory,
  type NavigationGuardWithThis,
  type RouteRecordRaw,
} from 'vue-router';
import { authManager, guardForCallbackPage, type User } from './plugins/auth';
import { useUser } from './store';
import { RouteNames } from './types/routes';

// by default a default project with id=0 is set
// therefore we can assume to redirect to it
export const HOME_ROUTE = { name: RouteNames.COMPLETE, params: { projectId: 0 } };

export function isRouteValid(value: RouteNames): boolean {
  return Object.values(RouteNames).includes(value);
}

const authGuard: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  if (
    !(
      to.matched.some((record) => record.meta.requiresAuth) ||
      to.matched.some((record) => record.meta.requiresBeta)
    )
  ) {
    next();
    return;
  }

  const user = useUser();
  if (!user.user) {
    await user.fetch();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!user.user || (user.user as User).expired) {
      await authManager.signinRedirect({ state: { to: to.fullPath } });
      return;
    }
  }

  if (
    (to.matched.some((record) => record.meta.requiresAuth) && !user.hasAccess) ||
    (to.matched.some((record) => record.meta.requiresBeta) && !user.hasBetaAccess)
  ) {
    next({ name: RouteNames.UNAUTHORIZED });
    return;
  }

  next();
};

export const routes: RouteRecordRaw[] = [
  // @ts-expect-error - this is a valid route
  {
    path: '/callback',
    name: RouteNames.LOGIN_CALLBACK,
    beforeEnter: async (to, from, next) => {
      await guardForCallbackPage(authManager, (returnedUser) => {
        const fallback = { name: 'App' };
        if (!returnedUser) {
          next(fallback);
          return;
        }
        const state = returnedUser.state as { to?: string };
        next(state.to ?? fallback);
      });
    },
  },
  {
    name: RouteNames.APP,
    path: '',
    components: {
      layout: () => import('@/layouts/BaseLayout.vue'),
    },
    redirect: HOME_ROUTE,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/projects/:projectId',
        name: RouteNames.PROJECTS,
        children: [
          ...traceRoutes,
          ...playgroundRoutes,
          ...evaluateRoutes,
          ...knowledgeRoutes,
          ...finetuneRoutes,
          ...monitorRoutes,
        ],
      },
      {
        name: RouteNames.NOTFOUND,

        path: '/:firstPathMatch?/:projectId?/:secondPathMatch(.*)*',
        components: {
          default: () => import('@/views/NotFound.vue'),
        },
      },
    ],
  },
  {
    path: '/:firstPathMatch?/:projectId?/:secondPathMatch(.*)*',
    redirect: { name: RouteNames.UNAUTHORIZED },
    components: {
      layout: () => import('@/layouts/BaseLayout.vue'),
    },
    children: [
      {
        name: RouteNames.UNAUTHORIZED,
        path: '',
        components: {
          default: () => import('@/views/Unauthorized.vue'),
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;
